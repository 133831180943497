import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {

  constructor(private router: Router,
        private activeModal: NgbActiveModal,
    
  ) { }

  ngOnInit(): void {
  }
  navigateToRoute(): void {
    this.router.navigate(['myProperties']);
    this.close();

  }
  close(rsn?: any): void {
   this.activeModal.close(rsn);
    
  }
  // dismissModal() {
  //   this.activeModal.dismiss('backdrop click');
  // }

}
