<div class="agent-grid">
  <div class="agent-card" *ngFor="let agent of agents">
    <div class="agent-container">
      <div class="agent-photo-container">
        <img [src]="agent.image" alt="{{ agent.name }}" class="agent-photo" />
      </div>
      <div class="agent-details-container">
        <div class="badges">
          <span *ngIf="agent.verified" class="verified-badge">✔ VERIFIED</span>
          <span *ngIf="agent.expert" class="expert-badge">Expert Pro Agent</span>
          <span *ngIf="agent.reraRegistered" class="rera-badge">✔ RERA REGISTERED</span>
        </div>
        <h3 class="agent-name">{{ agent.name }}</h3>
        <div class="agent-details">
          <span class="agent-rating">⭐ {{ agent.rating }}</span>
          <span class="agent-experience">{{ agent.experience }} Yrs</span>
        </div>
        <div class="agent-contact">
          <p>
            <img src="assets/img/cell-phone.png" style="width: 14px; height: auto;"> {{ agent.phone }}
          </p>
          <p>
            <img src="assets/img/gmail.png" style="width: 14px; height: auto;">  {{ agent.email }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Scroll Anchor -->
<div class="scroll-anchor" style="height: 1px;"></div>

<!-- Loading Spinner -->
<div *ngIf="loading" class="loading-spinner">
  <!-- <p>Loading more agents...</p> -->
</div>
