import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,NavigationEnd, Router } from '@angular/router';
import { EventBus, EmitEvent, Events } from 'src/app/common/events/event-bus.service';

import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';



export interface ISubmenuOption {
  submenuId: number;
  label: string;
  routeUrl?: string;
  sideSubMenuicon:string;
  active: boolean;
}
export interface IMenuOption {
  label: string;
  active: boolean;
  routeUrl?: string;
  sideMenuicon:string;
  submenuList?: Array<ISubmenuOption>;




}
@Component({
  selector: 'rsb-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  isNexusAddonOpen = false;
  subMenuLabelColl:boolean=false;
  subMenuLabelSkip:boolean;
  menuOptions: Array<IMenuOption> = [
    {
      label: 'Dashboard',
      active: true,
      routeUrl:'dashboard',
      sideMenuicon:'assets/img/dashboard.svg'
    },
    
    {
      label: 'Search Properties',
      active: false,
      routeUrl:'/search/searchProp',
      sideMenuicon:'assets/img/product.png'
    },
    
    // {
    //   label: 'Nexus-Network',
    //   active: false,
    //   routeUrl:'Nexus-Network',
    //   sideMenuicon:'assets/img/network.png'
    // },

    {
      label: 'My Team',
      active: false,
      routeUrl:'myTeam',
      sideMenuicon:'assets/img/Viewed Leads.svg'
    },
    {
      label: 'My Franchise',
      active: false,
      routeUrl:'myFranchise',
      sideMenuicon:'assets/img/franchise-agreement.png'
    },
    
    {
      label: 'Choose Plans',
      active: false,
      routeUrl:'choosePlan',
      sideMenuicon:'assets/img/money.svg'
    },
    {
      
      label: 'Post Property',
      active: false,
      // routeUrl:'post-property/new',
      routeUrl:'/Post-Property/propertyModules',
      sideMenuicon:'assets/img/projectfinance.svg'

    
    },
    {
      
      label: 'My Properties',
      active: false,
      // routeUrl:'post-property/new',
      routeUrl:'myProperties',
      sideMenuicon:'assets/mobile/img/real-estate.png'

    
    },
    {
      
      label: 'My Leads',
      active: false,
      routeUrl:'/account/leads-properties',
      sideMenuicon:"assets/img/leadicon.png"

    
    },

    
   
    // {
    //   label: 'My Profile',
    //   active: false,
    //   routeUrl:'associateMyProfile',
    //   sideMenuicon:'assets/img/user-line.svg'
      
    // },
   
   

    // {
    //   label: 'My Profile',
    //   active: false,
    //   routeUrl:'myProfile',
    //   sideMenuicon:'assets/img/Viewed Leads.svg'
    // },
    
   
    // {
    //   label: 'My Properties',
    //   active: false,
    //   routeUrl:'myProperties',
    //   sideMenuicon:'assets/img/myproperty.svg'

    
    // },
    // {
    //   label: 'My Projects',
    //   active: false,
    //   routeUrl:'myProjects',
    //   sideMenuicon:'assets/img/myproject.svg'
    //  },
    //  {
      
    //   label: 'My Leads',
    //   active: false,
    //   routeUrl:'leads',
    //   sideMenuicon:'assets/img/projectfinance.svg'    
    // },
    // {
      
    //   label: 'View Leads',
    //   active: false,
    //   routeUrl:'myViewLead',
    //   sideMenuicon:'assets/img/Viewed Leads.svg'
    // },

    
    {
      
      label: 'Sales',
      active: false,
      routeUrl:'sales',
      sideMenuicon:'assets/img/sale-tag.png'

    
    },
    {
      label: 'Nexus Ad-On',
      active: false,
      routeUrl: '',
      sideMenuicon: 'assets/img/sale.svg',
      submenuList: [
        // {
        //   submenuId: 1,
        //   label: 'Nexus Elite',
        //   routeUrl: '',
        //   sideSubMenuicon:'',
        //   active: false,
        // },
        {
          submenuId: 1,
          label: 'Loans',
          routeUrl: '/Nexus-Ad-Ons/loans',
          sideSubMenuicon:'assets/img/loan.svg',
          active: false,
        },
        {
          submenuId: 2,
          label: 'CIBIL Score',
          routeUrl: '/Nexus-Ad-Ons/cibil',
          sideSubMenuicon:'assets/img/cibil.svg',
          active: false,
        },
        {
          submenuId: 3,
          label: 'Project Finance',
          routeUrl: '/Nexus-Ad-Ons/projectFinance',
          sideSubMenuicon:'assets/img/projectfinance.svg',
          active: false,

        },
         {

           submenuId: 11,
           label: 'Solar Enquiry',
           routeUrl:'/Nexus-Ad-Ons/solarEnquiry',
           active: false,
           sideSubMenuicon:'assets/img/Solar Enquiry.svg',
          
         },
         {

          submenuId: 18,
          label: 'Furniture Enquiry',
          routeUrl:'/Nexus-Ad-Ons/Furniture-Enquiry',
          active: false,
          sideSubMenuicon:'assets/img/icons8-furniture-40.png',
         
        },
        {

          submenuId: 18,
          label: 'Interior Enquiry',
          routeUrl:'/Nexus-Ad-Ons/Interior-Enquiry',
          active: false,
          sideSubMenuicon:'assets/img/icons8-interior-40 (1).png',
         
        },
        {

          submenuId: 18,
          label: 'Packers Enquiry',
          routeUrl:'/Nexus-Ad-Ons/Packers-And-Movers-Enquiry',
          active: false,
          sideSubMenuicon:'assets/img/icons8-movers-40.png',
         
        },
         
        {
          submenuId: 4,
          label: 'CCTV Enquiry',
          routeUrl: '/Nexus-Ad-Ons/cctvEnquiry',
          active: false,
          sideSubMenuicon:'assets/img/cctv.svg',

        },
        {

          submenuId: 15,
          label: 'Land Survey',
          routeUrl:'/Nexus-Ad-Ons/land-survey',
          active: false,
          sideSubMenuicon:'assets/img/icons8-land-surveying-40.png',

        },{

          submenuId: 16,
          label: 'Land Fencing',
          active: false,
          routeUrl:'/Nexus-Ad-Ons/land-fencing',
          sideSubMenuicon:'assets/img/icons8-fence-42.png',

        },
        {

          submenuId: 16,
          label: 'Credit Card',
          active: false,
          routeUrl:'/Nexus-Ad-Ons/Credit-Card',
          sideSubMenuicon:'assets/img/icons8-credit-card-40.png',

        },
        {

          submenuId: 16,
          label: 'Home Automation',
          active: false,
          routeUrl:'/Nexus-Ad-Ons/Home-Automation',
          sideSubMenuicon:'assets/img/icons8-home-automation-40.png',

        },
        {

          submenuId: 16,
          label: 'Property Insurance',
          active: false,
          routeUrl:'/Nexus-Ad-Ons/Property-Insurance',
          sideSubMenuicon:'assets/img/icons8-property-40.png',

        },
        {

          submenuId: 16,
          label: 'Homes&Properties',
          active: false,
          routeUrl:'/Nexus-Ad-Ons/Prefab-Homes-And-Properties',
          sideSubMenuicon:'assets/img/icons8-home-40.png',

        },
        {

          submenuId: 16,
          label: 'Drones',
          active: false,
          routeUrl:'/Nexus-Ad-Ons/Drones',
          sideSubMenuicon:'assets/img/icons8-drone-40.png',

        }
        
        // {
        //   submenuId: 5,
        //   label: 'Interior Design Enquiry',
        //   routeUrl: 'interiorDesignEnquiry',
        //   active: false,
        // },
       
        // Additional submenus can be added here...
      ]
    },
    {
      
      label: 'Support & Help',
      active: false,
      routeUrl:'Support&Help',
      sideMenuicon:'assets/mobile/img/customer-service.png'

    
    },
    // {
      
    //   label: 'Payment Log',
    //   active: false,
    //   routeUrl:'payment-log',
    //   sideMenuicon:'assets/img/Interior Design Enquiry.svg'

    
    // },
   
    // {
      
    //   label: 'Reviews & Ratings',
    //   active: false,
    //   routeUrl:'reviewsAndRatings',
    //   sideMenuicon:'assets/img/Reviews.svg'

    
    // },

   
    // {
      
    //   label: 'Lead Center',
    //   active: false,
    //   routeUrl:'',
    //   sideMenuicon:'ri-group-3-line'

    
    // },
    // {
    //   label: 'Deals',
    //   active: false,
    //   routeUrl:'',
    //   sideMenuicon:'ri-shake-hands-line'

    
    // },
    // {
    //   label: 'Loans',
    //   active: false,
    //   routeUrl:'',
    //   sideMenuicon:'ri-money-rupee-circle-line'

    
    // },
    // {
    //   label: 'Support',
    //   active: false,
    //   routeUrl:'',
    //   sideMenuicon:'ri-settings-2-line'

    
    // },
  ]
  subMenuOpenFlag: boolean = false;
  currentUrl;
  constructor( 
    private router: Router,
    private route: ActivatedRoute,
    private rsbLookup:RsbLookupModel,
    private eventBus: EventBus,
  ) { }

  ngOnInit(): void {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (val.url !== '/') {
          const split = val.url.split('/');
          if (split.length === 2) {
            this.currentUrl = split[1];
            if (this.currentUrl) {
              this.menuOptions.map((obj) => {
                if (obj.routeUrl === this.currentUrl) {
                  obj.active = true;
                } else {
                  obj.active = false;
                }
              })
            }
          }
        }
      }
    })
  }
  // openMenuItem(menu: IMenuOption): void {
  //   if (!menu.active) {
  //     this.menuOptions.forEach(item => {
  //       item.active = false;
  //     });
  //   }
  //   this.subMenuOpenFlag = false;
  //   menu.active = !menu.active;
  
  //   if (menu.routeUrl && menu.active) {
  //     this.router.navigate([menu.routeUrl]);
  //   }
  // }
  collapse(){
    this.subMenuLabelColl=!this.subMenuLabelColl;
  }
  closeCollapse(){
    this.subMenuLabelColl=false;
  }
  toggleNexusAddon(event: Event) {
    event.stopPropagation(); // Prevent the event from propagating to the parent elements
    this.isNexusAddonOpen = !this.isNexusAddonOpen;
  }
  openMenuItem(menu: IMenuOption): void {
    this.menuOptions.forEach(item => {
      item.active = false;
      if (item.submenuList) {
        item.submenuList.forEach(submenu => submenu.active = false);
      }
    });
  
    menu.active = !menu.active;
  
    if (menu.label === 'Nexus AddOn' && menu.submenuList) {
      this.subMenuOpenFlag = !this.subMenuOpenFlag;
    } else {
      this.subMenuOpenFlag = false;
    }
  
    if (menu.routeUrl && menu.active) {
      this.router.navigate([menu.routeUrl]);
    }
  }
  
  openSubmenuItem(menu: IMenuOption, submenu: ISubmenuOption): void {
    if (!submenu.active) {
      menu.submenuList.forEach(item => {
        item.active = false;
      });
    }
    submenu.active = !submenu.active;
    if (submenu.active && submenu.routeUrl) {
      this.router.navigate([submenu.routeUrl]);
      this.eventBus.emit(new EmitEvent(Events.menuItemSelected, { title: submenu.label }));
    }
  }
  
  
  home(): void {
    this.router.navigate(['dashboard'])
  }


}