import { Component, OnInit, ChangeDetectorRef, Inject, PLATFORM_ID } from '@angular/core';
import { RouterExtService } from './common/services/router-ext.service';
import { RsbLookupModel } from './common/model/rsb-lookup.model';
import { isPlatformBrowser } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { RsbService } from './service/rsb.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'rsb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  openModalSubscription: Subscription;
  routerSubscription: Subscription;
  loading: boolean = false;
  hideFooter: boolean = false;
  hideFooterForLogin:boolean=false;
  hideMainSidebar: boolean = false;
  hideSidebar: boolean = false;
  hidePropertyBar: boolean = false;
  showPlotsideBar:boolean=false;
  hidePgBar:boolean=false;
  hideRentBar:boolean=false;
  hideFooterMob: boolean = false;
  hide:boolean = false;
  myProfileIncomplete:boolean = false;
  isMobileLayout: boolean = false;
  hideReviewsRating:boolean=false;
  showMobileNavForMyProperties:boolean=false
  
  private intervalId: any;

  hideHeaderNavForSearchPropInAssociate:boolean=false;
  hideHeaderNavForLogin:boolean=false;
  
  constructor(
    private routerExtService: RouterExtService,
    private lookupModel: RsbLookupModel,
    private rsbService:RsbService,
    private modalService: NgbModal,

    private router: Router,
    @Inject(PLATFORM_ID) platformId: any
  ) {
    this.lookupModel.isBrowser = isPlatformBrowser(platformId);
    this.routerSubscription = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
         this.hideMainSidebar = val.url.indexOf('/addCompany') !== -1 ? true : false;
         this.hideSidebar = val.url.indexOf('/addProject') !== -1 ? true : false;
          this.hidePropertyBar = val.url.indexOf('/post-property') !== -1 ? true : false;
          this.hidePropertyBar = val.url.indexOf('/post-property') !== -1 ? true : false;
          this.hideReviewsRating = val.url.indexOf('/reviewsAndRatings') !== -1 ? true : false;
          this.hideFooterForLogin = (val.url === '/' || val.url.includes('/login'));
          this.hidePgBar = val.url.indexOf('/Post-PG-Hostels/addPg') !== -1 ? true : false;
          this.hideRentBar = val.url.indexOf('/addRent') !== -1 ? true : false;
          this.showPlotsideBar = val.url.indexOf('/Add-plot-And-Land') !== -1 ? true : false;
          this.showMobileNavForMyProperties = val.url.indexOf('/myProperties') !== -1 ? true : false;


          this.hideHeaderNavForSearchPropInAssociate=val.url.indexOf('/search/searchProp')!== -1?true:false;

          this.hideHeaderNavForLogin = val.url === '/' || val.url === '/login'|| val.url === '' || val.url.trim() === '' || val.url ==='/forgotpassword';
         


      }
    })

  }
  
  ngOnInit() {
    this.isMobileLayout = window.innerWidth <= 1024;
    window.onresize = () => this.isMobileLayout = window.innerWidth <= 1024;
    // this.routerSubscription = this.router.events.subscribe((val) => {
    //   if (val instanceof NavigationEnd) {
    //     const myProfileIncomplete = val.url.indexOf('/myTeam');

    //     if(myProfileIncomplete){
    //       this.rsbService.getAssociateProfileInfo().subscribe((rsp) => {
    //         const personalInfo = rsp.contents[0];
    //         const AssProfileInfo = personalInfo.personalDetails;
    //         if (!AssProfileInfo.firstName || !AssProfileInfo.lastName
    //           || !AssProfileInfo.email || !AssProfileInfo.mobile || !AssProfileInfo.bloodGroup
    //           || !AssProfileInfo.emergencyContactNumber || !AssProfileInfo.profilePic
    //           || !AssProfileInfo.experience
    //           || !AssProfileInfo.qualification || !AssProfileInfo.rera
    //         ) {
    //           this.startInterval();
      
    //         }
    //       })
         
    //      }
    //   }})
  
  }
  // ngOnDestroy(): void {
  //   this.clearInterval();
  // }

  // startInterval() {
  //   this.intervalId = setInterval(() => {      
  //     const modelRef = this.modalService.open(IncompleteProfilePopupAlertComponent, { size: 'lg', });
  //   }, 6000);
  // }

  // clearInterval() {
  //   if (this.intervalId) {
  //     clearInterval(this.intervalId);
  //   }
  // }
}
