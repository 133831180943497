import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ForgotpasswordComponent } from './shared/components/forgotpassword/forgotpassword.component';
import { AuthGuard } from './common/guards/auth-guard';
import { AddAttributesCompnentComponent } from './components/PG-hostels/add-attributes-compnent/add-attributes-compnent.component';
import { LoginAssociateComponent } from './components/login-associate/login-associate.component';
import { NexusNetworkComponent } from './components/nexus-network/nexus-network.component';

// import { MyProjectsComponent } from './components/vendor/my-projects/my-projects.component';
// import { AddCompanyComponent } from './components/companies/add-company/add-company.component';
// import { CompanySidebarComponent } from './components/companies/company-sidebar/company-sidebar.component';
// import { CompanyinfoComponent } from './components/companies/companyinfo/companyinfo.component';
// import { CompanyProfileComponent } from './components/companies/company-profile/company-profile.component';
// import { AddProjectComponent } from './components/projects/add-project/add-project.component';
// import { MyLeadsComponent } from './components/vendor/my-leads/my-leads.component';
// import { MyviewLeadsComponent } from './components/myview-leads/myview-leads.component';
// import { MyCompanyComponent } from './components/my-company/my-company.component';
// import { ReviewsAndRatingsComponent } from './components/vendor/reviews-and-ratings/reviews-and-ratings.component';
// import { MyProfileComponent } from './components/vendor/my-profile/my-profile.component';
// import { LeadsComponent } from './components/vendor/leads/leads.component';
// import { SalesComponent } from './components/vendor/sales/sales.component';
// import { InteriorDesignEnquiryComponent } from './components/vendor/interior-design-enquiry/interior-design-enquiry.component';
// import { SearchPropertiesComponent } from './components/associates/search-properties/search-properties.component';
// import { PropertyListingsComponent } from './components/property-listings/property-listings.component';
// import { ProjectListingsComponent } from './components/project-listings/project-listings.component';
// import { PgListingsComponent } from './components/pg-listings/pg-listings.component';
// import { PropertyDetailsComponent } from './property-details/property-details.component';
// import { ProjectDescriptionComponent } from './project-description/project-description.component';
// import { PgDescriptionComponent } from './pg-description/pg-description.component';
// import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
// import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
// import { ShippingPolicyComponent } from './components/shipping-policy/shipping-policy.component';
// import { CancellationRefundPolicyComponent } from './components/cancellation-refund-policy/cancellation-refund-policy.component';
// import { LeadsForProjectsComponent } from './components/leads-for-projects/leads-for-projects.component';
// import { LeadsForPghostelComponent } from './components/leads-for-pghostel/leads-for-pghostel.component';
// import { PricingPolicyComponent } from './components/pricing-policy/pricing-policy.component';
// import { AboutUsComponent } from './components/about-us/about-us.component';
// import { ContactUsComponent } from './components/contact-us/contact-us.component';
// import { MbProfileEditComponent } from './components/mobile-layout-components/mb-profile-edit/mb-profile-edit.component';
// import { MbSearchPropertyPage1Component } from './components/mobile-layout-components/mb-search-property-page1/mb-search-property-page1.component';
// import { MbAddMoreLocationPageComponent } from './components/mobile-layout-components/mb-add-more-location-page/mb-add-more-location-page.component';
// import { PlotAndLandListingsComponent } from './components/plot-and-land-listings/plot-and-land-listings.component';
// import { BuyListingsComponent } from './components/buy-listings/buy-listings.component';
// import { PostPropertyComponent } from './components/vendor/post-property/post-property.component';
const routes: Routes = [

  // {
  //   path: 'property-details/:propertyName',
  //   component:PropertyDetailsComponent
  // },
  // {
  //   path: 'project-description/:propertyName',
  //   component:ProjectDescriptionComponent
  // },
  // {
  //   path: 'pg-description/:propertyName',
  //   component:PgDescriptionComponent
  // },
  // {
  //   path: 'contactUs',
  //   canActivate:[AuthGuard],
  //   component:ContactUsComponent
  // },
  // {
  //   path: 'privacy-policy',
  //   canActivate:[AuthGuard],
  //   component:PrivacyPolicyComponent
  // },
  // {
  //   path: 'aboutUs',
  //   canActivate:[AuthGuard],
  //   component:AboutUsComponent
  // },
  // {
  //   path: 'pricing-policy',
  //   canActivate:[AuthGuard],
  //   component:PricingPolicyComponent
  // },
  // {
  //   path: 'terms-conditions',
  //   canActivate:[AuthGuard],
  //   component:TermsAndConditionsComponent
  // },
  // {
  //   path: 'shipping-policy',
  //   canActivate:[AuthGuard],
  //   component:ShippingPolicyComponent
  // }, {
  //   path: 'Cancellation-Refundpolicy',
  //   canActivate:[AuthGuard],
  //   component:CancellationRefundPolicyComponent
  // },
  // {
  //   path:'myProjects',
  //   canActivate:[AuthGuard],
  //   component:MyProjectsComponent
  // },
  // {
  //   path:'reviewsAndRatings',
  //   canActivate:[AuthGuard],
  //   component:ReviewsAndRatingsComponent
  // },
  // {
  //   path:'myProfile',
  //   canActivate:[AuthGuard],
  //   component:MyProfileComponent
  // },
    // {
  //   path:'interiorDesignEnquiry',
  //   canActivate:[AuthGuard],
  //   component:InteriorDesignEnquiryComponent
  // },
    // {
  //   path:'leads-projects',
  //   component:LeadsForProjectsComponent
  // },{
  //   path:'leads-pgHostels',
  //   component:LeadsForPghostelComponent
  // },

  // {
  //   path:'leads',
  //   canActivate:[AuthGuard],
  //   component:LeadsComponent
  // },
  
  // {
  //   path:'addCompany/:companyName',
  //   canActivate:[AuthGuard],
  //   component: AddCompanyComponent
  // },
  // {
  //   path:'addProject/:propertyId',
  //   canActivate:[AuthGuard],
  //   component: AddProjectComponent
  // },
  // {
  //   path:'companyInfo',
  //   canActivate:[AuthGuard],
  //   component: CompanyinfoComponent
  // },
  // {
  //   path:'myLead',
  //   canActivate:[AuthGuard],
  //   component: MyLeadsComponent
  // },
  // {
  //   path:'myViewLead',
  //   canActivate:[AuthGuard],
  //   component:MyviewLeadsComponent
  // },
  // {
  //   path:'myCompany',
  //   canActivate:[AuthGuard],
  //   component:MyCompanyComponent
  // },
  // {
  //   path:'myCompany',
  //   canActivate:[AuthGuard],
  //   component:PostPropertyComponent
  // },

  // {
  //   path:'companyProfile',
  //   canActivate:[AuthGuard],
  //   component: CompanyProfileComponent
  // },

  // {
  //   path:'pgLocation',
  //   canActivate:[AuthGuard],
  //   component:PgLocationComponent

  // },

  
  {
    path: '', redirectTo: '/login', pathMatch: 'full' ,
  },
  {
    path: 'addAttributes',
    canActivate:[AuthGuard],
    component:AddAttributesCompnentComponent
  }, 

 



  // {
  //   path: 'listings/:location/:type',
  //   canActivate:[AuthGuard],
  //   component: PropertyListingsComponent
  // },
  // {
  //   path: 'project/:location/:type',
  //   canActivate:[AuthGuard],
  //   component:ProjectListingsComponent
  // },
  // {
  //   path: 'plot/:location/:type',
  //   canActivate:[AuthGuard],
  //   component:PlotAndLandListingsComponent
  // },
  // {
  //   path: 'buy/:location/:type',
  //   canActivate:[AuthGuard],
  //   component:BuyListingsComponent
  // },
  // {
  //   path: 'pg/:location',
  //   canActivate:[AuthGuard],
  //   component:PgListingsComponent
  // },
  // {
  //   path:'profile-edit',
  //   component:MbProfileEditComponent,
  // },


  // {
  //   path:'search-prop-page1',
  //   canActivate:[AuthGuard],
  //   component:MbSearchPropertyPage1Component
  // },
  // {
  //   path:'add-more-locations',
  //   canActivate:[AuthGuard],
  //   component:MbAddMoreLocationPageComponent
  // },
  {
    path:'Nexus-Network',
    component:NexusNetworkComponent

  }, 

  {
    path:'sales',
    canActivate:[AuthGuard],
    loadChildren:()=>import('./components/staticRouting/sales-module/sales-module.module').then(m=>m.SalesModuleModule)

  },  

  {
    path:'login',
    component:LoginAssociateComponent
  },
  {
    path:'myProperties',
    canActivate:[AuthGuard],
    loadChildren:()=>import('./components/staticRouting/my-properties-module/my-properties-module.module').then(m=>m.MyPropertiesModuleModule)
  },

  {
    path:'Support&Help',
    canActivate:[AuthGuard],
    loadChildren: () => import('./components/staticRouting/support-module/support-module.module').then(m => m.SupportModuleModule)
  },
  {
    path:'choosePlan',
    canActivate:[AuthGuard],
    loadChildren: () => import('./components/staticRouting/choose-plan-module/choose-plan-module.module').then(m => m.ChoosePlanModuleModule)
  },
  {
    path:'search',
    canActivate:[AuthGuard],
    loadChildren: () => import('./components/staticRouting/search-properties-module/search-properties-module.module').then(m => m.SearchPropertiesModuleModule)
  },
  {
    path:'descriptions',
    canActivate:[AuthGuard],
    loadChildren: () => import('./components/staticRouting/search-descriptions-module/search-descriptions-module.module').then(m => m.SearchDescriptionsModuleModule)
  },
  {
    path:'Nexus-Ad-Ons',
    canActivate:[AuthGuard],
    loadChildren: () =>import('./components/staticRouting/nexus-modules/nexus-modules.module').then(m=>m.NexusModulesModule)
  },
  {
    path:'myTeam',
    canActivate:[AuthGuard],
    loadChildren: () => import('./components/staticRouting/my-team/my-team.module').then(m => m.MyTeamModule)
  },
  {
    path:'myFranchise',
    canActivate:[AuthGuard],
    loadChildren: () => import('./components/staticRouting/my-franchise-module/my-franchise-module.module').then(m => m.MyFranchiseModuleModule)
  },
  {
    path:'account',
    canActivate:[AuthGuard],
    loadChildren: () => import('./components/staticRouting/account-module/account-module.module').then(m => m.AccountModuleModule)
  },
  {
    path: 'dashboard',
    canActivate:[AuthGuard],
    loadChildren: () => import('./components/staticRouting/dashboard-module/dashboard-module.module').then(m => m.DashboardModuleModule)

  },
  {
    path: 'Post-Property',
    canActivate:[AuthGuard],
    loadChildren: () => import('./components/staticRouting/post-property-module/post-property-module.module').then(m => m.PostPropertyModuleModule)

  },
  {
    path: 'Edit-Post-Property',
    canActivate:[AuthGuard],
    loadChildren: () => import('./components/staticRouting/edit-post-property-module/edit-post-property-module.module').then(m => m.EditPostPropertyModuleModule)

  },
  {
    path: 'Post-PG-Hostels',
    canActivate:[AuthGuard],
    loadChildren: () => import('./components/staticRouting/post-pg-hostels-module/post-pg-hostels-module.module').then(m => m.PostPgHostelsModuleModule)

  },
  {
    path: 'Post-Plot-And-Land',
    canActivate:[AuthGuard],
    loadChildren: () => import('./components/staticRouting/post-plot-and-land-module/post-plot-and-land-module.module').then(m => m.PostPlotAndLandModuleModule)

  },
  {
    path: 'Post',
    canActivate:[AuthGuard],
    loadChildren: () => import('./components/staticRouting/post-homes-commercial-module/post-homes-commercial-module.module').then(m => m.PostHomesCommercialModuleModule)

  },


  {
    path:'forgotpassword',
    // canActivate:[AuthGuard],
    component:ForgotpasswordComponent
  },
  { path: '**', redirectTo: '/login',pathMatch: 'full' } 
 

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})

export class AppRoutingModule { }
