<div class="container" (click)="navigateToRoute()">
<div class="feedback-container">
    <button type="button" class="close" (click)="close()">&times;</button>

    <h5 class="mt-5"><b>Rate Your Experience With PillarBlocks</b></h5> 
    <p>How did that process make you feel?</p>
    <div class="emoji-container">
      <!-- Great -->
      <button class="emoji-btn" (click)="navigateToRoute()">
        <img src="assets/img/happy.png" style="width: 50px; height: auto;" alt="Great Emoji" />
        <span>Great</span>
      </button>
      <!-- Not Ok -->
      <button class="emoji-btn" (click)="navigateToRoute()">
        <img src="assets/img/sad.png" style="width: 50px; height: auto;" alt="Not Ok Emoji" />
        <span>Not Ok</span>
      </button>
      <!-- Don't Know -->
      <button class="emoji-btn" (click)="navigateToRoute()">
        <img src="assets/img/confusion.png" style="width: 50px; height: auto;" alt="Don't Know Emoji" />
        <span>Don't Know</span>
      </button>
    </div>
  </div>
  </div>
  