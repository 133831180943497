import { HttpClient } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { RsbService } from 'src/app/service/rsb.service';

@Component({
  selector: 'app-nexus-network',
  templateUrl: './nexus-network.component.html',
  styleUrls: ['./nexus-network.component.css']
})
export class NexusNetworkComponent implements OnInit,OnDestroy {
  isMobileLayout: boolean = false;
  agents: any[] = [];
  skip: number = 0;
  limit: number = 10;
  loading: boolean = false;
  hasMoreData: boolean = true;
  observer: IntersectionObserver | undefined;

  constructor(private rsbService: RsbService) {}

  ngOnInit(): void {
    this.isMobileLayout = window.innerWidth <= 1024;
    window.onresize = () => (this.isMobileLayout = window.innerWidth <= 1024);
    this.loadAgents();
    this.setupIntersectionObserver();
  }
  loadAgents(): void {
    if (this.loading || !this.hasMoreData) return;
    this.loading = true;
    const args = {
      skip: this.skip,
      limit: this.limit
    };
    this.rsbService.moreNexusNetworkData(args).subscribe((response: any) => {
        const newAgents = response.data;
        this.agents = [...this.agents, ...newAgents];
        if (newAgents.length < this.limit) {
          this.hasMoreData = false;
        }
        // Update skip for the next API call
        this.skip += this.limit;
        this.loading = false;
      });
  }

  setupIntersectionObserver(): void {
    const options = {
      root: null,
      threshold: 0.1
    };

    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !this.loading) {
          this.loadAgents();
        }
      });
    }, options);

    const target = document.querySelector('.scroll-anchor');
    if (target) {
      this.observer.observe(target);
    }
  }

  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}