(function () {
  "use strict";

  /**
   * Easy selector helper function
   */
  const select = (el, all = false) => {
    el = el.trim();
    if (all) {
      return [...document.querySelectorAll(el)];
    } else {
      return document.querySelector(el);
    }
  };

  /**
   * Easy event listener function
   */
  const on = (type, el, listener, all = false) => {
    if (all) {
      select(el, all).forEach((e) => e.addEventListener(type, listener));
    } else {
      select(el, all).addEventListener(type, listener);
    }
  };

  /**
   * Easy on scroll event listener
   */
  const onscroll = (el, listener) => {
    el.addEventListener("scroll", listener);
  };

  /**
   * Toggle .header-scrolled class to #header when page is scrolled
   */
  let selectHeader = select(".filterheader");
  let selectHeader1 = select("#header");
  if (selectHeader) {
    const headerScrolled = () => {
      if (window.scrollY > 100) {
        selectHeader.classList.add("header-scrolled");
        selectHeader.classList.add("fixed-top");
        selectHeader1.classList.remove("fixed-top");
      } else {
        selectHeader.classList.remove("header-scrolled");
        selectHeader.classList.remove("fixed-top");
        selectHeader1.classList.add("fixed-top");
      }
    };
    window.addEventListener("load", headerScrolled);
    onscroll(document, headerScrolled);
  }

  /**
   * Back to top button
   */
  let backtotop = select(".back-to-top");
  if (backtotop) {
    const toggleBacktotop = () => {
      if (window.scrollY > 100) {
        backtotop.classList.add("active");
      } else {
        backtotop.classList.remove("active");
      }
    };
    window.addEventListener("load", toggleBacktotop);
    onscroll(document, toggleBacktotop);
  }

  /**
   * Mobile nav toggle
   */
  on("click", ".mobile-nav-toggle", function (e) {
    select("#navbar").classList.toggle("navbar-mobile");
    this.classList.toggle("bi-list");
    this.classList.toggle("bi-x");
  });

  /**
   * Mobile nav dropdowns activate
   */
  on(
    "click",
    ".navbar .dropdown > a",
    function (e) {
      if (select("#navbar").classList.contains("navbar-mobile")) {
        e.preventDefault();
        this.nextElementSibling.classList.toggle("dropdown-active");
      }
    },
    true
  );

  /**
   * Animation on scroll
   */
  function aos_init() {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false,
    });
  }
  window.addEventListener("load", () => {
    aos_init();
  });

  /**
   * Initiate Pure Counter
   */
  new PureCounter();
})();

$(document).ready(function ($) {
  var sliders = [
    { id: "#curated_slider", items: 4 },
    { id: "#featured_slider", items: 4 },
    { id: "#featured_slider1", items: 3 },
    { id: "#Popluar_slider", items: 3 },
    { id: "#Trending_slider", items: 3 },
    { id: "#TopProperty_slider", items: 4 },
    { id: "#agentblock_slider", items: 3 },
    { id: "#bottomproperty_slider, #bottompropertyrent_slider", items: 4 },
    { id: "#recommened_slider", items: 3 },
    { id: "#about_slider", items: 3 },
    { id: "#Latest_Project_slider", items: 3 },
    { id: "#map_slider", items: 3 },
    { id: "#verified_slider", items: 3 },
    { id: "#Similar_slider", items: 3 },
  ];

  $.each(sliders, function (index, slider) {
    var $slider = $(slider.id);
    $slider.owlCarousel({
      items: slider.items,
      margin: 10,
      touchDrag: true,
      pagination: false,
      dots: false,
      nav: true,
      navText: [
        '<i class="bi bi-arrow-left"></i>',
        '<i class="bi bi-arrow-right"></i>',
      ],
      responsiveClass: true,
      responsive: {
        0: { items: 1 },
        600: { items: slider.id === "#featured_slider1" ? 2 : 3 },
        1000: { items: slider.items },
      },
      onInitialized: handleInitialized,
    });

    function handleInitialized(event) {
      if (event.item.index === 0) {
        $slider.find(".owl-prev").hide();
      }
    }

    $slider.on("changed.owl.carousel", function (event) {
      if (event.item.index + 1 === 1) {
        $slider.find(".owl-prev").hide();
      } else {
        $slider.find(".owl-prev").show();
      }
    });
  });
});
// Carosuel End

// property search page
$(".moreplaces").hide();
$(".moreloc").click(function () {
  $(".moreplaces").toggle("1000");
  $("i", this).toggleClass("bi bi-chevron-down bi bi-chevron-up");
});

$(document).ready(function () {
  // Toggle dropdown menus on click
  $(".filter_item li.dropdown > a").click(function (e) {
    e.preventDefault();
    var $dropdown = $(this).siblings(".dropdown-menu");
    $(".dropdown-menu").not($dropdown).hide();
    var $overlay = $(".filter-overlay");
    $overlay.toggleClass("show"); // Toggle overlay immediately
    setTimeout(function () {
      if ($dropdown.is(":hidden")) {
        $overlay.removeClass("show");
      }
    }, 200); // Adjust the delay as needed
    if ($dropdown.is(":hidden")) {
      $dropdown.show();
    } else {
      $dropdown.hide();
    }
    $("i", this).toggleClass("bi bi-chevron-down bi bi-chevron-up");
  });

  // // Close menu when clicking outside
  // $(document).click(function(e) {
  //   var $dropdown = $('.filter_item li.dropdown > a');
  //   if (!$dropdown.is(e.target) && $dropdown.has(e.target).length === 0) {
  //     $('.dropdown-menu').hide();
  //     $('.filter-overlay').removeClass('show');
  //     // Optionally, toggle the icon back to its original state
  //     $("i", $dropdown).removeClass("bi-chevron-up").addClass("bi-chevron-down");
  //   }

  // });
});
$(document).ready(function () {
  const scrollAmount = 500; // Adjust scroll speed by changing this value
  const $menu = $(".menu");
  const $prevBtn = $(".prev-btn");
  const $nextBtn = $(".next-btn");

  $prevBtn.hide(); // Initially hide previous button

  $menu.scroll(function () {
    $prevBtn.toggle($menu.scrollLeft() > 0);
    $nextBtn.toggle(
      $menu.scrollLeft() + $menu.width() < $menu.prop("scrollWidth")
    );
  });

  $prevBtn.click(function () {
    $menu.animate({ scrollLeft: `-=${scrollAmount}` }, "slow");
  });

  $nextBtn.click(function () {
    $menu.animate({ scrollLeft: `+=${scrollAmount}` }, "slow");
  });
});

$(document).ready(function () {
  $(".more_amenities").hide();
  $(".viewmore").click(function () {
    $(".more_amenities").slideToggle("slow");
  });
});

const lightbox = GLightbox({
  touchNavigation: true,
  loop: true,
  width: "90vw",
  height: "90vh",
});

$(document).ready(function () {
  $(window).scroll(function () {
    var scrollTop = $(window).scrollTop();
    var contentOffset = $(".property_content_wrapper").offset().top;
    var footerOffset = $(".footer_wrapper").offset().top;
    var headerHeight = $(".header").outerHeight();

    if (
      scrollTop > contentOffset - headerHeight &&
      scrollTop < footerOffset - $(window).height()
    ) {
      $(".right_fixed").addClass("scrolled");
      $(".menu-container").addClass("scrolled");
    } else {
      $(".right_fixed").removeClass("scrolled");
      $(".menu-container").removeClass("scrolled");
    }
  });
});
$(document).ready(function () {
  $(".menu-list li a").click(function () {
    // Remove active class from all list items
    $(".menu-list li a").removeClass("active");

    // Add active class to the clicked list item
    $(this).addClass("active");
  });
});
